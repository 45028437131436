import { GetServerSideProps } from 'next';
import { getServerSideSitemap, ISitemapField } from 'next-sitemap';
import axios from "axios";
import { apiConstants } from '../../constants/apiConstants';

interface SlugsDataI {
  data: { slugs: { slug: string }[] }
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const REGION = process.env.REGION ?? '';
  let fields: ISitemapField[] = [];
  const { data }: SlugsDataI = await axios.get(
    apiConstants.ssrServer.server.replace(
      '[--REGION--]',
      `${REGION}`
    ) + '/public/catalog/products/slugs/all?l=100000&o=0'
  );
  fields = data.slugs.map((cur) => ({
    loc: process.env.NEXT_PUBLIC_SITE_URL || 'https://driffle.com' + `/${cur.slug}`,
    lastmod: new Date().toISOString(),
  }))
  return getServerSideSitemap(ctx, fields);
};

export default function Site() { }
