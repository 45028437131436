
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/products-sitemap.xml",
      function () {
        return require("private-next-pages/products-sitemap.xml/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/products-sitemap.xml"])
      });
    }
  